// import './assets/main.css'

import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from './plugins/axios'
// import ElementPlus from './plugins/element'
import elementIcon from '@/plugins/svgicon'
import 'element-plus/dist/index.css'
import './styles/element-variables.scss'
import i18n from './language/i18n.js'
import directives from './directives'
// import VueGtag from 'vue-gtag'
import 'normalize.css/normalize.css'
import 'nprogress/nprogress.css'
import './styles/commontype.css'
import websocket from './utils/websocket'
import horizontalScroll from 'el-table-horizontal-scroll'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import dayjs from 'dayjs'
import Vue3CountryIntl from 'vue3-country-intl'
import 'vue3-country-intl/lib/vue3-country-intl.css'
const app = createApp(App)
app.config.warnHandler = () => null
app.config.globalProperties.day = dayjs // 全局挂载
app.component(Vue3CountryIntl.name, Vue3CountryIntl)
app.use(VXETable)
app.use(horizontalScroll)
app.use(store)
app.use(i18n)
app.use(router)
app.use(ElementPlus)
app.use(elementIcon)
app.use(axios, { router, store, opt: 'VE_API' })
app.use(directives, { router, store })
app.provide('websocket', websocket)

function sendErrorToFeishu(errorInfo) {
    const webhookUrl = 'https://open.feishu.cn/open-apis/bot/v2/hook/77451fce-51a6-4c61-9b2f-e864c16bccd4';

    const payload = {
      msg_type: 'interactive',
      card: {
        "config": {},
        "i18n_elements": {
            "zh_cn": [
                {
                    "tag": "column_set",
                    "flex_mode": "none",
                    "horizontal_spacing": "default",
                    "background_style": "default",
                    "columns": [
                        {
                            "tag": "column",
                            "elements": [
                                {
                                    "tag": "div",
                                    "text": {
                                        "tag": "plain_text",
                                        "content": JSON.stringify(errorInfo, null, 2),
                                        "text_size": "normal",
                                        "text_align": "left",
                                        "text_color": "default"
                                    }
                                }
                            ],
                            "width": "weighted",
                            "weight": 1
                        }
                    ]
                }
            ]
        },
        "i18n_header": {
            "zh_cn": {
                "title": {
                    "tag": "plain_text",
                    "content": "【前端BUG】前端哥们儿来活啦 🎉🎉🎉"
                },
                "subtitle": {
                    "tag": "plain_text",
                    "content": ""
                },
                "template": "red"
            }
        }
      }
    };

    fetch(webhookUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .catch(error => {
      console.error('Error sending error to Feishu:', error);
    });
  }

// 添加 window.onerror 监听器
window.onerror = function (message, source, lineno, colno, error) {
    var user_info = localStorage.getItem('cs_user_info')
    if (user_info) {
        user_info = JSON.parse(user_info)
    }
    var source = window.location.href
    // 跳过本地报错
    if (source.indexOf('127.0.0.1') !== -1 || source.indexOf('localhost') !== -1) {
        return
    }
    // 这里可以将错误信息发送到服务器
    sendErrorToFeishu({ message, source, lineno, colno, error, user_info })
}

// 添加 promise中的错误监听
window.addEventListener('unhandledrejection', event => {
    var user_info = localStorage.getItem('cs_user_info')
    if (user_info) {
        user_info = JSON.parse(user_info)
    }
    var source = window.location.href
    // 跳过本地报错
    if (source.indexOf('127.0.0.1') !== -1 || source.indexOf('localhost') !== -1) {
        return
    }
    // 这里可以将错误信息发送到服务器
    sendErrorToFeishu({ message: event.reason.message, source, stack: event.reason.stack, user_info })
})

if(process.env.NODE_ENV === 'production' && import.meta.env.MODE === 'development'){
    //上线build development模式下不需要https
    const meta = document.querySelector('meta[http-equiv="Content-Security-Policy"]');
    if (meta) {
        meta.remove();
    }
}else{
    const meta = document.createElement('meta');
    meta.setAttribute('http-equiv', 'Content-Security-Policy');
    meta.setAttribute('content', 'upgrade-insecure-requests');
    document.head.appendChild(meta);
}

app.mount('#app')

