export default [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/about_us',
    name: 'about_us',
    component: () => import('../views/about_us.vue')
  },
  {
    path: '/workspace',
    name: 'workspace',
    component: () => import('../views/workspace.vue')
  },
  {
    path: '/manage_center',
    name: 'manage_center',
    component: () => import('../views/manage_center/index.vue')
  },
  {
    path: '/data_center',
    name: 'data_center',
    component: () => import('../views/data_center.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404_page.vue')
  },
  {
    path: '/molgen_relation',
    name: 'molgen_relation',
    component: () => import('../views/molgen_relation.vue')
  },
  {
    path: '/molgen_mcmg',
    name: 'molgen_mcmg',
    component: () => import('../views/molgen_mcmg.vue')
  },
  {
    path: '/molgen_pocket',
    name: 'molgen_pocket',
    component: () => import('../views/molgen_pocket.vue')
  },
  {
    path: '/molgen_ligand',
    name: 'molgen_ligand',
    component: () => import('../views/molgen_ligand.vue')
  },
  // 分子工厂
  {
    path: '/molecular_factory',
    name: 'molecular_factory',
    component: () => import('../views/molecularFactory/index.vue')
  },
  // 分子工厂任务结果详情
  {
    path: '/molecular_factory_task_result/:task_id',
    name: 'molecular_factory_task_result',
    component: () => import('../views/molecularFactory/components/result/index.vue')
  },
  // 分子工厂比较
  {
    path: '/molecular_factory/contrast',
    name: 'contrast',
    component: () => import('../views/molecularFactory/components/contrast/index.vue')
  },
  // 分子详情
  {
    path: '/molecular_details',
    name: 'molecular_details',
    component: () => import('../views/molecular_details.vue')
  },
  // 分子对比
  {
    path: '/molecular_contrast',
    name: 'molecular_contrast',
    component: () => import('../views/molecular_contrast.vue')
  },
  {
    path: '/molgen_cluster_table/:task_id',
    name: 'molgen_cluster_table',
    component: () => import('../views/molgen_cluster_table.vue')
  },
  {
    path: '/molgen_single_cluster/:task_id/:cluster_id/:cluster_num',
    name: 'molgen_single_cluster',
    component: () => import('../views/molgen_single_cluster.vue')
  },
  {
    path: '/molgen_single_mol/:task_id/:cluster_id/:smiles_id/:smiles_number/:smiles/:cluster_num',
    name: 'molgen_single_mol',
    component: () => import('../views/molgen_single_mol.vue')
  },
  {
    path: '/admet_dl',
    name: 'admet_dl',
    component: () => import('../views/admet_dl.vue')
  },
  {
    path: '/admet_sa',
    name: 'admet_sa',
    component: () => import('../views/admet_sa.vue')
  },
  {
    path: '/admet_fh',
    name: 'admet_fh',
    component: () => import('../views/admet_fh.vue')
  },
  {
    path: '/spectrum_ai',
    name: 'spectrum_ai',
    component: () => import('../views/spectrum_ai.vue')
  },
  {
    path: '/spectrum_table/:task_id',
    name: 'spectrum_table',
    component: () => import('../views/spectrum_table.vue')
  },
  {
    path: '/admet_table/:task_id',
    name: 'admet_table',
    component: () => import('../views/admet_table.vue')
  },
  {
    path: '/admet_details',
    name: 'admet_details',
    component: () => import('../views/admet_details.vue')
  },
  {
    path: '/admetfh_table/:task_id',
    name: 'admetfh_table',
    component: () => import('../views/admetfh_table.vue')
  },
  {
    path: '/admetsa_table/:task_id',
    name: 'admetsa_table',
    component: () => import('../views/admetsa_table.vue')
  },
  {
    path: '/molgen_table/:task_id',
    name: 'molgen_table',
    component: () => import('../views/molgen_table.vue')
  },
  {
    path: '/screen_docking',
    name: 'screen_docking',
    component: () => import(/* webpackPrefetch: true */ '../views/screen_docking.vue')
  },
  {
    path: '/screen_docking_results/:task_id',
    name: 'screen_docking_results',
    component: () => import('../views/screen_docking_results.vue')
  },
  {
    path: '/screen_rescoring',
    name: 'screen_rescoring',
    component: () => import('../views/screen_rescoring.vue')
  },
  {
    path: '/screen_rescoring_results/:task_id',
    name: 'screen_rescoring_results',
    component: () => import('../views/screen_rescoring_results.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/signup.vue')
  },
  {
    path: '/signin',
    name: 'signin',
    component: () => import('../views/signin.vue')
  },
  {
    path: '/user_rule/:name',
    name: 'user_rule',
    component: () => import('../views/user_rule.vue')
  },
  {
    path: '/forgot_password',
    name: 'forgot_password',
    component: () => import('../views/forgot_password.vue')
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import('../views/activate.vue')
  },
  {
    path: '/activate_transit',
    name: 'activate_transit',
    component: () => import('../views/activate_transit.vue')
  },
  {
    path: '/pswd_transit',
    name: 'pswd_transit',
    component: () => import('../views/pswd_transit.vue')
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('../views/setting_page.vue')
  },
  {
    path: '/model_qsar',
    name: 'model_qsar',
    component: () => import('../views/model_qsar.vue')
  },
  {
    path: '/model_use',
    name: 'model_use',
    component: () => import('../views/model_use.vue')
  },
  {
    path: '/model_qsar_results/:task_id',
    name: 'model_qsar_results',
    component: () => import('../views/model_qsar_results.vue')
  },
  {
    path: '/model_qsar_use/:task_id',
    name: 'model_qsar_use',
    component: () => import('../views/model_qsar_use.vue')
  },
  {
    path: '/ligand_prep',
    name: 'ligand_prep',
    component: () => import('../views/ligand_prep.vue')
  },
  {
    path: '/screen_virtual',
    name: 'screen_virtual',
    component: () => import('../views/screen_virtual.vue')
  },
  {
    path: '/screen_virtual_result/:task_id',
    name: 'screen_virtual_result',
    component: () => import('../views/virtual_result/index.vue')
  },
  {
    path: '/screen_virtual_details',
    name: 'screen_virtual_details',
    component: () => import('../views/screen_virtual_details.vue')
  },
  {
    path: '/screen_virtual_contrast',
    name: 'screen_virtual_contrast',
    component: () => import('../views/screen_virtual_contrast.vue')
  },
  {
    path: '/ligand_prep_results/:task_id',
    name: 'ligand_prep_results',
    component: () => import('../views/ligand_prep_results.vue')
  },
  {
    path: '/molstar',
    name: 'molstar',
    component: () => import('../views/molstar.vue')
  },
  {
    path: '/protein_preparation',
    name: 'protein_preparation',
    component: () => import('../views/protein_preparation/index.vue')
  },
  {
    path: '/protein_preparation_result/:task_id',
    name: 'protein_preparation_result',
    component: () => import('../views/protein_preparation/protein_preparation_result.vue')
  },
  {
    path: '/personal_center',
    name: 'personal_center',
    component: () => import('../views/personal_center/index.vue')
  },
  // {
  //   path: '/data_extraction',
  //   name: 'data_extraction',
  //   component: () => import('../views/data_extraction/index.vue')
  // },
  {
    path: '/data_extraction',
    name: 'data_extraction',
    component: () => import('../views/data_extraction/submit_task.vue')
  },
  {
    path: '/extraction_results/:task_id',
    name: 'extraction_results',
    component: () => import('../views/data_extraction/results.vue')
  },
  {
    path: '/balance_details',
    name: 'balance_details',
    component: () => import('../views/balance/balance_details.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/balance/recharge.vue')
  }
]
