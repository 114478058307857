import { createStore } from 'vuex'
import getters from './getters'

const modules = {}
// const files = require.context('./modules', false, /\.js$/)
// files.keys().forEach((key) => {
//   const fileName = key.replace(/(\.\/|\.js)/g, '')
//   modules[fileName] = files(key).default
// })
export default createStore({
  getters,
  modules
})
