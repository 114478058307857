import { createRouter, createWebHashHistory } from 'vue-router'
import globalRoutes from './globalRoutes'
import { ref } from 'vue'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { get_cookie } from '@/utils/common'
NProgress.configure({
  easing: 'ease',
  speed: 500,
  showSpinner: false,
  trickleSpeed: 200,
  minimum: 0.3
})

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  },
  isAddDynamicMenuRoutes: false,
  routes: globalRoutes
})

router.beforeEach((to, from, next) => {
  const token = get_cookie('csrftoken') // sessionid
  const if_authed = ref(false)
  const path_public_list = [
    'index',
    '404',
    'signin',
    'signup',
    'user_rule',
    'activate',
    'forgot_password',
    'activate_transit',
    'pswd_transit'
  ]
  if (to.name !== 'screen_dock') {
    NProgress.start()
  }

  if (localStorage.getItem('cs_signined') === 'true') {
    if_authed.value = true
  }

  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  if (!if_authed.value) {
    if (path_public_list.indexOf(to.name) !== -1) {
      next()
    } else {
      next({
        name: 'signin',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    next()
  }
})

router.afterEach((to, from, next) => {
  if (to.name !== 'screen_dock') {
    NProgress.done()
  }
})

export default router
